import React from 'react';
import '../style/pdf.css'
const Order = () => {
  return (
    <div className="container">
      <hr />
    
      <div className='header'>
      <div>logo</div>
       <div className='orden'> <h2>Orden de Compra Nº 789</h2>
       <h3>SOLICITADO POR EMPRESA COMERCIAL ETHOS S.A.</h3></div>
      </div>

      <hr />
      <br />
      <p><strong>Fecha:</strong> 02/07/2024</p>
      <div className='datosempresa'><p><strong>R.U.T:</strong> 76390967-0</p>
        <p><strong>Telefono:</strong> +569xxxxxxx</p></div>
      <p><strong>Señor(es):</strong> IMPORTADORA Y COMERCIALIZADORA MEDICPRO LIMITADA</p>

      <p><strong>Dirección:</strong> GUARDIA VIEJA 181, OFICINA 506, Santiago, RM</p>


      <p className="info"><strong>Estimado(a) Señor(a),<br/> Agradecemos su compra y entregamos lo siguiente</strong></p>
      <table className='tabladetalle'>
        <thead>
          <tr className='header-table'>
            <th>Código</th>
            <th>Cód. proveedor</th>
            <th>Cantidad</th>
            <th>Descripción</th>
            <th>Unidad</th>
            <th>Precio Unitario ($)</th>
          
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>DM-GNTE55-C</td>
            <td>30</td>
            <td>GASA N/T ESTERIL 5X5 CJ X 50 UNID</td>
            <td>CAJA</td>
            <td>1,200.00</td>
            <td>36,000</td>
          </tr>
          <tr>
            <td>DM-VGE144-U (III)</td>
            <td>12</td>
            <td>VENDA GASA ELASTICADA 4 CM X 4 MTS</td>
            <td>UNIDAD</td>
            <td>1,440.00</td>
            <td>14,400</td>
          </tr>
          <tr>
            <td>IN-IDMIX1004-U</td>
            <td>200</td>
            <td>APOSITO GASA NO TEJIDA 20 X 25 CM ESTERIL</td>
            <td>UNIDAD</td>
            <td>195.00</td>
            <td>39,000</td>
          </tr>
          <tr>
            <td>DM-AG2040-U</td>
            <td>400</td>
            <td>APOSITO GASA N/TEJIDA ESTERIL 20 X 40</td>
            <td>UNIDAD</td>
            <td>430.00</td>
            <td>172,000</td>
          </tr>
          <tr>
            <td>DM-AP1010-U</td>
            <td>300</td>
            <td>APOSITO GASA ESTERIL 10 X 10</td>
            <td>UNIDAD</td>
            <td>93.00</td>
            <td>27,900</td>
          </tr>
          
        </tbody>
      </table>
      
   <table >
   <tr >
            <td>Fecha Entrega: 01/01/2024</td>
            <td></td>
            <td></td>
            <td></td>
            <td className='total'>TOTAL NETO</td>
            <td className='total'>289.000</td>
          </tr>
          <tr >
          <td>Forma de Pago: 30 días</td>
            <td></td>
            <td></td>
            <td></td>
            <td className='total'>IVA</td>
            <td className='total'>54.967</td>
          </tr>
          <tr >
          <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className='total'>TOTAL A PAGAR</td>
            <td className='total'>344.267</td>
          </tr>
   </table>
      <p className="info"><strong>Condiciones Generales:</strong></p>
      <ul>
        <li>Indicar Nº de Orden de compra en factura.</li>
        <li>El pago será nominativo a nombre del proveedor.</li>
        <li>Las OC. en US$ se pagaran al dólar observado del día de facturación.</li>
        <li>Si esta orden le llega por mail, le solicitamos verificarla con la gerencia.</li>
      </ul>
    </div>
  );
};

export default Order;
