import { Route, Routes, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { Home } from '../Home/Screen/Home.jsx'


import { Adquisicion } from '../Adquisicion/Screen/AdquisicionAsia.jsx'

import { SeguimientoAdquisicion } from '../Adquisicion/Screen/SeguimientoAdquisicion.jsx'

import { ListaProductos } from '../ControlStock/Screen/ListaProductos.jsx'
import { ListaProductosEdit } from '../ControlStock/Screen/ListaProductosEdit.jsx'


import { Clientes } from '../Gestion_Administrativa/Screen/Clientes.jsx'
import { Productos } from '../Gestion_Administrativa/Screen/Productos.jsx'
import { Proveedores } from '../Gestion_Administrativa/Screen/Proveedores.jsx'

import { IngresarVenta } from '../Ventas_Facturas/Screen/IngresarVenta.jsx'
import { ListadeOrdenCompra } from '../Ventas_Facturas/Screen/ListadeOrdenCompra.jsx'

import { GestionUsuarios } from '../Administracion_Personas/Screen/GestionUsuarios.jsx'
import { PermisosyCargos } from '../Administracion_Personas/Screen/PermisosyCargos.jsx'

import { Loginpage } from '../Login/Screen/Loginpage.jsx'
import { RedirectScreen } from '../Login/Screen/RedirectScreen.jsx'
import { Sidebar } from '../Utility/SideBar.jsx'
import { Login } from '../Helpers/Login.jsx'
import { Envios } from '../Bodega/Screen/Envios.jsx'
import { Picking } from '../Bodega/Screen/Picking.jsx'
import { TipoVenta } from '../Configuracion/Screen/TipodeVenta.jsx'
import { Shipper } from '../Configuracion/Screen/Shipper.jsx'
import { Paises } from '../Configuracion/Screen/Paises.jsx'
import { Bancos } from '../Configuracion/Screen/Bancos.jsx'
import { Facturas } from '../Despacho_Facturas/Screen/Facturas.jsx'
import { Despachos } from '../Despacho_Facturas/Screen/Despachos.jsx'
import { IngresarFactura } from '../Despacho_Facturas/Screen/IngresarFactura.jsx'
import Order from '../Bodega/Screen/PDF.jsx'








export const AppRoutes = () => {
  let location = useLocation();
  const [showsidebar, setShowsidebar] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia('(max-width: 900px)').matches);

  useEffect(() => {
    const handleResize = (event) => {
      setIsSmallScreen(event.matches);
      // Actualiza showsidebar basado en el tamaño de pantalla
  
      setShowsidebar(!event.matches); // Aquí es donde se cambia `showsidebar` a true o false
    };

    const mediaQueryList = window.matchMedia('(max-width: 900px)');
    mediaQueryList.addEventListener('change', handleResize);

    // Ejecutar el efecto inicial
    handleResize(mediaQueryList);

    return () => {
      mediaQueryList.removeEventListener('change', handleResize);
    };
  }, []);

  return (
    <> {location.pathname.includes("Login") ? null : <Sidebar showsidebar={showsidebar} setshowsidebar={setShowsidebar} />}
      <div className={location.pathname.includes("Login") ? null : showsidebar?"bloque-layout":"bloque-layoutf"}>
        <Routes>
        <Route path='/*' element={<RedirectScreen />} />
        <Route path='Home' element={<Home />} />
         

          {/* Login  */}
          <Route path='Login' element={<Loginpage />} />

          {/* Adquisiciones */}
          <Route path='Generar-adquisicion-comex' element={<Adquisicion />} /> 

          <Route path='Seguimiento-Pedidos' element={<SeguimientoAdquisicion />} />

          {/* Control Stock */}
          <Route path="Lista-Productos" element={<ListaProductos />} />
          <Route path="Manejo-de-Stock" element={<ListaProductosEdit />} />
          <Route path="Agregar/Retirar-Productos" element={<ListaProductos />} />
          
          {/* Ventas */}
          <Route path='Ingreso-Orden-de-Compra' element={<IngresarVenta />} />
          <Route path='Adm.-de-Orden-de-Compra' element={<ListadeOrdenCompra />} />

          {/* Gestion Administracion */}
          <Route path='Clientes' element={<Clientes />} />
          <Route path='Productos' element={<Productos />} />
          <Route path='Proveedores' element={<Proveedores />} />


          {/*Adm Personas*/}
          <Route path='Usuarios' element={<GestionUsuarios />} />
          <Route path='Permisos-y-Cargos' element={<PermisosyCargos />} />


          {/* Facturas y Despacho */}

          <Route path='Ingresar-Factura' element={<IngresarFactura />} />
          <Route path='Facturas' element={<Facturas/>}/>
          <Route path='Despacho-Bodega' element={<Despachos/>}/>

          {/* Configuraciones */}
          <Route path='Bancos' element={<Bancos/>}/>
          <Route path='Paises' element={<Paises/>}/>
          <Route path='Shippers' element={<Shipper/>}/>
          <Route path='Tipo-Venta' element={<TipoVenta/>}/>
          {/* Bodega */}
          <Route path='Picking' element={<Picking/>}/>
          <Route path='Despachos' element={<Envios/>}/>
    <Route path='Rutas' element={<Order/>}/>


          {/* Error page */}
       <Route path='*/paginaError' element={<Login />}/>   

        </Routes>
      </div>
    </>
  )
}
