import React, { useState } from 'react'
import { Modal } from '../../Utility/Modal'
import { DragAndDropOrdenCompra } from '../../Ventas_Facturas/Components/DragAndDropIngresoCompra';
let Inputform = ({ titulo, name, type, value, setform, formulario, Disabled = false }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setform({ ...formulario, [name]: value });

    };

    return (
        < >
            <label className='element-input'>

                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    disabled={Disabled}
                />

            </label>
        </>)
}
const Selectform = ({ opciones, titulo, form, setform, name, Disabled = false }) => {

    const handleChange = (e) => {
        setform({ ...form, [name]: e.target.value });
    };

    return (
        <div className='element-input'>
            <label htmlFor="miSelect" className='modifier-titulo'>{titulo}</label>
            <select className='modifier-input' id="miSelect" value={form.tipopago} onChange={handleChange} disabled={Disabled}>
                <option value="" >
                    Selecciona una opción
                </option>
                {Object.keys(opciones).map((key) => (
                    <option key={key} value={key}>
                        {opciones[key]}
                    </option>
                ))}
            </select>
        </div>
    );
}
export const FormPago = ({ handleModal, setFactura, Factura }) => {
    let tipopagos = { "Tranferencia": "Tranferencia", "Efectivo": "Efectivo", "Cheque": "Cheque" }
    let tipocheque = { "Día": "Día", "30 Días": "30 Días", "60 Días": "60 Días", "90 Días": "90 Días", "120 Días": "120 Días", }
    const [formapago, setformapago] = useState({ tipopago: "", monto: "", fechacheque: "-", tipocheque: "-", estado: "Pendiente", montopagado: "" ,ndoc:"",banco:"",adjunto:{ Documento: '', Url: '', Nombre: '' }})
    const [Modalshowdocumento, setModalshowdocumento] = useState(false)
    let handleModaldocumento = () => {
        setModalshowdocumento(!Modalshowdocumento)
    }
    let handleaddpago = () => {
        let nuevopago = Factura.metodosdepago

        nuevopago.push(formapago)
     
        setFactura({ ...Factura, metodosdepago: nuevopago })
        handleModal()
    }
    let handleadjunto=()=>{
        setformapago({...formapago,adjunto: { Documento: '', Url: '', Nombre: '' }})
      }
    return (
        <div style={{ padding: "10px" }}>

            <h3>Agregar Pago</h3>
            <hr />
            <br />
            <Selectform
                opciones={tipopagos}
                titulo="Formas de Pago"
                name='tipopago'
                form={formapago}
                setform={setformapago}
            />
            <Inputform
                titulo='Monto'
                name='monto'
                type='number'
                value={formapago.monto}
                setform={setformapago}
                formulario={formapago}

            />
            <Inputform
                titulo='Fecha Ingreso'
                name='fechacheque'
                type='date'
                value={formapago.fechacheque}
                setform={setformapago}
                formulario={formapago}

            />
            <Selectform
                opciones={tipocheque}
                titulo="Forma de Pago"
                name='tipocheque'
                form={formapago}
                setform={setformapago}
                Disabled={formapago.tipopago === "Cheque" ? false : true}
            />
            <Inputform
                titulo='N.Doc'
                name='ndoc'
                type='text'
                value={formapago.ndoc}
                setform={setformapago}
                formulario={formapago}
                Disabled={formapago.tipopago === "Cheque" ? false : true}
            />
            <Inputform
                titulo='Banco'
                name='banco'
                type='text'
                value={formapago.banco}
                setform={setformapago}
                formulario={formapago}
                Disabled={formapago.tipopago === "Cheque" ? false : true}
            />
                <div className='element-subirdocumentos'>
          {formapago.adjunto.Documento===""?<><span>Adjuntar Documento:</span>
            <img style={{ cursor: 'pointer', margin: '5px 50px 5px 50px' }} onClick={handleModaldocumento} src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1707758619/pwd8nqsfmzaxazd3s2sd.png" alt="imagen de subir" width={'40px'} /></>
:<><span style={{width:"300px"}}>Documento: {formapago.adjunto.Documento} </span> &nbsp; <img onClick={handleadjunto} src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1712525937/hlmyv9pradkhasmviblc.png" alt="icon-close" width={"20px"} height={"18px"} /></>}
        </div>
            

            <div className='element-save-bar'>
                <button className="element-boton cerrar" onClick={handleModal}>Cerrar</button>
                <button className="element-boton agregar" onClick={handleaddpago}>Agregar</button>
            </div>
            <Modal showModal={Modalshowdocumento} closeModal={handleModaldocumento}>
                <DragAndDropOrdenCompra
                    Adquisiciones={formapago}
                    setadquisiciones={setformapago}
                    handleModal={handleModaldocumento}
                    ordencompra={formapago.adjunto}
                    iditem={`${Factura.ordendecompra}${Math.floor(Date.now() / 1000)}`} />
            </Modal>
        </div>
    )
}
