import React, { useEffect, useState } from 'react'
import Popup from '../../Utility/PopUpNotificacion';
import { useGetAction } from '../../Hooks/useGetAction';
import { POSTAction } from '../../Helpers/POST';
import { sleep } from '../../Utility/sleep';
import { HeaderModal } from '../../Utility/HeaderModal';

export const AsignacionChofer = ({item,handleModal}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })
const [conductores, setconductores] = useState([])
    let { loading, data } = useGetAction('usuarios/obtenerconductores')


    useEffect(() => {
        if(!loading){
            setconductores(data);
        }
       }, [data,loading])
       

       const [selectedChofer, setSelectedChofer] = useState('');

       // Maneja el cambio en el combobox
       const handleChange = (event) => {
         setSelectedChofer(event.target.value);
       };

let Actualizarchofer=async()=>{

    let nuevoitem={...item}
    nuevoitem.chofer=selectedChofer
    
    let res=await POSTAction(nuevoitem,"despachosyfacturas/actualizardespacho")

    setShowPopup(true);
    setmsgpopup({ tipo: 'exito', msg: "Conductor asignado correctamente" })
    if (!res.succes) {
        setmsgpopup({ tipo: 'error', msg: res.estado })
    }
    await sleep(2000)
    setTimeout(() => {
        setShowPopup(false);
    }, 4000);
    return res.succes && handleModal();
}

  return (
    <div>
      {showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
      <HeaderModal titulo='Asignación Conductor' handleModal={handleModal}/>
      <hr /> 
      <br />
      <label className='modifier-titulo' htmlFor="chofer-select">Selecciona un Conductor</label>&nbsp; &nbsp;&nbsp;
      <select
        id="chofer-select"
        value={selectedChofer}
        onChange={handleChange}
         className='modifier-input-selct'
      >
        <option value="">Conductores</option>
        {conductores.map((chofer) => (
          <option key={chofer._id} value={chofer._id}>
            {chofer.nombres} {chofer.apellidos}
          </option>
        ))}
      </select>

      <div className='element-save-bar'>
                  <button className="element-boton cerrar" onClick={()=>handleModal()}>Cerrar</button>
                  <button className="element-boton guardar" onClick={()=>{Actualizarchofer()}} style={{width:'120px'}}>Guardar</button>
                </div>


      </div>
  )
}
